<template>
  <div>
    <b-form>
      <div
        class="position-relative"
        :class="
          person.SourceUUID === null || person.SourceUUID === ''
            ? ''
            : 'onfocus'
        "
        ref="municipality"
      >
        <b-form-select
          id="fm_municipality"
          :aria-label="labels.Municipality"
          v-model="person.SourceUUID"
          ref="municipality_form-control"
          @focus.native="onFocus('municipality')"
          @blur.native="onBlur('municipality')"
          :options="sourceSelectionOptions"
          :class="
            submitted && $v.person.SourceUUID.$error
              ? 'is-invalid mb-1 rounded vgs-form-select'
              : 'input-margin rounded vgs-form-select'
          "
        >
        </b-form-select>
        <label
          class="vgs-ac-label"
          for="fm_municipality"
          ref="municipality_label"
          >{{ labels.Municipality + " *" }}</label
        >
        <div class="vgs-select-chevron">
          <b-icon icon="chevron-down"></b-icon>
        </div>
        <div
          v-if="submitted && $v.person.SourceUUID.$error"
          class="invalid-feedback error-class"
        >
          <span v-if="!$v.person.SourceUUID.required">{{
            labels.RequiredField
          }}</span>
        </div>
      </div>

      <div
        v-if="
          registerTypeName === 'BPK-SA' || person.TypeName === 'NaturalPerson'
        "
      >
        <div
          class="position-relative"
          :class="
            person.Salutation === null || person.Salutation === ''
              ? ''
              : 'onfocus'
          "
          ref="salutation"
        >
          <b-form-select
            id="fm_salutation"
            :aria-label="labels.Salutation"
            ref="salutation_form-control"
            @blur.native="onBlur('salutation')"
            @focus.native="onFocus('salutation')"
            v-model="person.Salutation"
            class="input-margin rounded vgs-form-select"
          >
            <b-form-select-option value="1">Frau</b-form-select-option>
            <b-form-select-option value="2">Herr</b-form-select-option>
          </b-form-select>
          <label
            class="vgs-ac-label"
            for="fm_salutation"
            ref="salutation_label"
            >{{ labels.Salutation }}</label
          >
          <div class="vgs-select-chevron">
            <b-icon icon="chevron-down"></b-icon>
          </div>
        </div>
        <div
          class="position-relative"
          :class="
            person.FirstName === null || person.FirstName === ''
              ? ''
              : 'onfocus'
          "
          ref="firstname"
        >
          <b-form-input
            id="fm_firstname"
            :aria-label="labels.FirstName"
            type="text"
            @blur.native="onBlur('firstname')"
            @focus.native="onFocus('firstname')"
            v-model="person.FirstName"
            ref="firstname_form-control"
            :class="{
              'is-invalid mb-1 rounded vgs-form-select':
                $v.person.FirstName.$error,
              'input-margin rounded vgs-form-select': !$v.person.FirstName.$error,
            }"
            :disabled="shouldDisableNames && setPerson.FirstName !== ''"
          ></b-form-input>
          <label
            class="vgs-ac-label"
            for="fm_firstname"
            ref="Firstname_label"
            >{{ labels.FirstName + " *" }}</label
          >
          <div
            v-if="$v.person.FirstName.$error"
            class="invalid-feedback error-class"
          >
            <span v-if="!$v.person.FirstName.required">{{
              labels.RequiredField
            }}</span>
          </div>
        </div>

        <div
          class="position-relative"
          :class="
            person.LastName === null || person.LastName === '' ? '' : 'onfocus'
          "
          ref="lastname"
        >
          <b-form-input
            id="fm_lastname"
            @blur.native="onBlur('lastname')"
            @focus.native="onFocus('lastname')"
            :aria-label="labels.LastName"
            type="text"
            v-model="person.LastName"
            ref="lastname_form-control"
            :class="{
              'is-invalid mb-1 rounded vgs-form-select':
                submitted && $v.person.LastName.$error,
              'input-margin rounded vgs-form-select':
                !submitted || !$v.person.LastName.$error,
            }"
            :disabled="shouldDisableNames && setPerson.LastName !== ''"
          ></b-form-input>
          <label class="vgs-ac-label" for="fm_lastname" ref="lastname_label">{{
            labels.LastName + " *"
          }}</label>
          <div
            v-if="submitted && $v.person.LastName.$error"
            class="invalid-feedback error-class"
          >
            <span
              v-if="!$v.person.LastName.required"
              class="error-new-class"
              >{{ labels.RequiredField }}</span
            >
          </div>
        </div>

        <b-row>
          <b-col cols="4 pr-1">
            <div
              class="position-relative"
              :class="
                person.AcademicTitles === null || person.AcademicTitles === ''
                  ? ''
                  : 'onfocus'
              "
              ref="academictitle"
            >
              <b-form-input
                id="fm_academictitle"
                ref="academictitle_form-control"
                :aria-label="labels.AcademicTitle"
                maxlength="50"
                type="text"
                v-model="person.AcademicTitles"
                class="input-margin rounded vgs-form-input nocontrols"
                @blur.native="onBlur('academictitle')"
                @focus.native="onFocus('academictitle')"
              ></b-form-input>
              <label class="vgs-ac-label" for="fm_academictitle">{{
                labels.AcademicTitle
              }}</label>
            </div>
          </b-col>
          <b-col cols="8 pl-1">
            <div
              class="position-relative"
              :class="
                person.PostNominalTitles === null ||
                person.PostNominalTitles === ''
                  ? ''
                  : 'onfocus'
              "
              ref="postnominaltitles"
            >
              <b-form-input
                id="fm_postnominaltitles"
                ref="postnominaltitles_form-control"
                :aria-label="labels.PostNominalTitles"
                maxlength="50"
                type="text"
                v-model="person.PostNominalTitles"
                @blur.native="onBlur('postnominaltitles')"
                @focus.native="onFocus('postnominaltitles')"
                class="input-margin rounded vgs-form-input"
              ></b-form-input>
              <label class="vgs-ac-label" for="fm_postnominaltitles">{{
                labels.PostNominalTitles
              }}</label>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <div
          class="position-relative"
          :class="person.Name === null || person.Name === '' ? '' : 'onfocus'"
          ref="name"
        >
          <b-form-input
            id="fm_name"
            :aria-label="labels.Name"
            type="text"
            @blur.native="onBlur('name')"
            @focus.native="onFocus('name')"
            v-model="person.Name"
            ref="name_form-control"
            :class="{
              'is-invalid mb-1 rounded vgs-form-select': $v.person.Name.$error,
              'input-margin rounded vgs-form-select': !$v.person.Name.$error,
            }"
            :disabled="shouldDisableNames && setPerson.Name !== ''"
          ></b-form-input>
          <label class="vgs-ac-label" for="fm_name" ref="name_label">{{
            labels.Name + " *"
          }}</label>
          <div
            v-if="$v.person.Name.$error"
            class="invalid-feedback error-class"
          >
            <span v-if="!$v.person.Name.required">{{
              labels.RequiredField
            }}</span>
          </div>
        </div>
      </div>
      <b-row class="row">
        <div class="col span-2 vcs-title">{{ labels.DeliveryData }}</div>
      </b-row>
      <b-row>
        <b-col cols="8 pr-1">
          <div
            class="position-relative"
            :class="
              userAddress.Street === null || userAddress.Street === ''
                ? ''
                : 'onfocus'
            "
            ref="street"
          >
            <b-form-input
              id="fm_street"
              ref="street_form-control"
              :aria-label="labels.Street"
              maxlength="50"
              type="text"
              v-model="userAddress.Street"
              @blur.native="onBlur('street')"
              @focus.native="onFocus('street')"
              class="input-margin rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_street">{{
              labels.Street
            }}</label>
          </div>
        </b-col>
        <b-col cols="4 pl-1">
          <div
            class="position-relative"
            :class="
              userAddress.StreetNumber === null ||
              userAddress.StreetNumber === ''
                ? ''
                : 'onfocus'
            "
            ref="streetNumber"
          >
            <b-form-input
              id="fm_streetnumber"
              ref="streetNumber_form-control"
              :aria-label="labels.StreetNumber"
              maxlength="50"
              type="text"
              v-model="userAddress.StreetNumber"
              @blur.native="onBlur('streetNumber')"
              @focus.native="onFocus('streetNumber')"
              class="input-margin rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_streetnumber">{{
              labels.StreetNumber
            }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6 pr-1">
          <div
            class="position-relative"
            :class="
              userAddress.Staircase === null || userAddress.Staircase === ''
                ? ''
                : 'onfocus'
            "
            ref="staircase"
          >
            <b-form-input
              id="fm_staircase"
              ref="staircase_form-control"
              :aria-label="labels.Staircase"
              maxlength="50"
              type="text"
              v-model="userAddress.Staircase"
              @blur.native="onBlur('staircase')"
              @focus.native="onFocus('staircase')"
              class="input-margin rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_staircase">{{
              labels.Staircase
            }}</label>
          </div>
        </b-col>
        <b-col cols="6 pl-1">
          <div
            class="position-relative"
            :class="
              userAddress.ApartmentNumber === null ||
              userAddress.ApartmentNumber === ''
                ? ''
                : 'onfocus'
            "
            ref="apartmentnumber"
          >
            <b-form-input
              id="fm_apartmentnumber"
              ref="apartmentnumber_form-control"
              :aria-label="labels.ApartmentNumber"
              maxlength="50"
              type="text"
              v-model="userAddress.ApartmentNumber"
              @blur.native="onBlur('apartmentnumber')"
              @focus.native="onFocus('apartmentnumber')"
              class="input-margin rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_apartmentnumber">{{
              labels.ApartmentNumber
            }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4 pr-1">
          <div
            class="position-relative"
            :class="
              userAddress.PostalCode === null || userAddress.PostalCode === ''
                ? ''
                : 'onfocus'
            "
            ref="postalcode"
          >
            <b-form-input
              id="fm_postalcode"
              ref="postalcode_form-control"
              @keydown="checkLength"
              :aria-label="labels.PostalCode"
              type="number"
              v-model="userAddress.PostalCode"
              class="input-margin rounded vgs-form-input nocontrols"
              @blur.native="onBlur('postalcode')"
              @focus.native="onFocus('postalcode')"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_postalcode">{{
              labels.PostalCode
            }}</label>
          </div>
        </b-col>
        <b-col cols="8 pl-1">
          <div
            class="position-relative"
            :class="
              userAddress.City === null || userAddress.City === ''
                ? ''
                : 'onfocus'
            "
            ref="city"
          >
            <b-form-input
              id="fm_city"
              aria-label="labels.City"
              ref="city_form-control"
              type="text"
              maxlength="50"
              v-model="userAddress.City"
              @blur.native="onBlur('city')"
              @focus.native="onFocus('city')"
              class="input-margin rounded vgs-form-input"
            ></b-form-input>
            <label class="vgs-ac-label" for="fm_city">{{ labels.City }}</label>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="
          registerTypeName === 'BPK-SA' || person.TypeName === 'NaturalPerson'
        "
        class="position-relative"
        :class="dateofbirth === null || dateofbirth === '' ? '' : 'onfocus'"
        ref="dateofbirth"
      >
        <b-form-input
          id="fm_dateofbirth"
          ref="dateofbirth_form-control"
          :aria-label="labels.DateOfBirth"
          type="text"
          maxlength="10"
          @focus.native="onFocus('dateofbirth')"
          @blur.native="onBlur('dateofbirth')"
          required
          v-model="dateofbirth"
          :class="
            submitted && $v.dateofbirth.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'input-margin rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_dateofbirth">{{
          labels.DateOfBirth + " (TT.MM.JJJJ)"
        }}</label>
        <div v-if="$v.dateofbirth.$error" class="invalid-feedback error-class">
          <span v-if="!$v.dateofbirth.isDate">{{
            labels.UserDataFormBirthDateNotValid
          }}</span>
        </div>
      </div>
      <div class="mb-3 text-left vgs-font-small" v-if="shouldHidePassword">
        {{ labels.PasswordComplexityInfo }}
      </div>
      <div class="position-relative" ref="password" v-if="shouldHidePassword">
        <b-form-input
          id="fm_password"
          ref="password_form-control"
          :aria-label="labels.Password"
          v-bind:type="this.passwordType"
          maxlength="20"
          v-model="password"
          v-on:keyup="set_password_complexity_level_handler"
          @focus.native="onFocus('password')"
          @blur.native="onBlur('password')"
          :class="
            submitted && $v.password.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'input-margin rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_password">{{
          labels.Password + " *"
        }}</label>
        <div class="vgs-pw-eye" @click="fm_password_switch()">
          <IconSet icon="eye" state=""></IconSet>
        </div>
        <div v-if="$v.password.$error" class="invalid-feedback error-class">
          <span v-if="!$v.password.required">{{
            labels.PasswordRequired
          }}</span>
        </div>
      </div>

      <div
        :class="!$v.password.$error && $v.password.required ? 'mb-5' : 'mb-3'"
        v-if="shouldHidePassword"
      >
        <PasswordComplexity
          v-if="!$v.password.$error && $v.password.required"
          v-bind:level="this.password_complexity_level"
        ></PasswordComplexity>
      </div>

      <div
        class="position-relative"
        ref="passwordconfirmation"
        v-if="shouldHidePassword"
      >
        <b-form-input
          id="fm_passwordconfirmation"
          ref="passwordconfirmation_form-control"
          :aria-label="labels.PasswordRepeated"
          type="password"
          maxlength="20"
          @focus.native="onFocus('passwordconfirmation')"
          @blur.native="onBlur('passwordconfirmation')"
          v-model="passwordconfirmation"
          :class="
            submitted && $v.passwordconfirmation.$error
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'mb-4 rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_passwordconfirmation">{{
          labels.PasswordRepeated + " *"
        }}</label>
        <div
          v-if="$v.passwordconfirmation.$error"
          class="invalid-feedback error-class"
        >
          <span v-if="!$v.passwordconfirmation.required">{{
            labels.PasswordRequired
          }}</span>
          <span
            v-if="
              !$v.passwordconfirmation.sameAsPassword &&
              $v.passwordconfirmation.required
            "
            >{{ labels.ConfirmPasswordNotMatched }}</span
          >
        </div>
      </div>
      <div class="warning-message-box">
        <div class="alert-icon">
          <b-icon icon="info-circle"></b-icon>
        </div>
        <div class="alert-text">
          {{ labels.EmailInfoText }}
        </div>
      </div>
      <div
        class="position-relative"
        :class="
          userAddress.Email === null || userAddress.Email === ''
            ? ''
            : 'onfocus'
        "
        ref="email"
      >
        <b-form-input
          id="fm_email"
          ref="email_form-control"
          :aria-label="labels.EMail"
          type="email"
          maxlength="50"
          @focus.native="onFocus('email')"
          @blur.native="onBlur('email')"
          required
          v-model="userAddress.Email"
          :class="
            (submitted && $v.userAddress.Email.$error) ||
            EmailAlreadyExistsError
              ? 'is-invalid mb-1 rounded vgs-form-input'
              : 'input-margin rounded vgs-form-input'
          "
        ></b-form-input>
        <label class="vgs-ac-label" for="fm_email">{{
          labels.EMail + " *"
        }}</label>
        <div
          v-if="EmailAlreadyExistsError"
          class="invalid-feedback error-class"
        >
          <span>{{ labels.EmailAlreadyExists }}</span>
        </div>
        <div
          v-if="$v.userAddress.Email.$error"
          class="invalid-feedback error-class"
        >
          <span v-if="!$v.userAddress.Email.required">{{
            labels.RequiredField
          }}</span>
          <span
            v-if="!$v.userAddress.Email.email && $v.userAddress.Email.required"
            >{{ labels.EmailInvalidFormat }}</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12">
          <b-form-checkbox
            class="AltEmaillabel"
            id="messageSetting.ActiveAlternateEmail"
            v-model="mailShow"
            name="messageSetting.ActiveAlternateEmail"
            >{{ labels.GetAlternateEmail }}</b-form-checkbox
          >
        </div>
      </div>
      <div class="row" v-if="mailShow">
        <div class="col-md-12">
          <div class="leftspace">
            <label class="" for="diffAlternateMail">{{
              labels.AlternateEmail
            }}</label>
            <b-form-input
              type="text"
              id="diffAlternateMail"
              @focus.native="$v.diffAlternateMail.$touch()"
              v-model="diffAlternateMail"
              :placeholder="labels.AlternateEmail"
              :aria-label="labels.AlternateEmail"
              :class="{ 'is-invalid': $v.diffAlternateMail.$error }"
              class="input-height mb-2 rounded vgs-form-input Alt-input"
            ></b-form-input>
            <div
              v-if="differentEmailError"
              class="invalid-feedback error-class"
            >
              <span>{{ labels.AlternateEmailAlreadyExists }}</span>
            </div>
            <div
              v-if="$v.diffAlternateMail.$error"
              class="invalid-feedback error-class"
            >
              <span
                v-if="!$v.diffAlternateMail.emailCheck"
                class="float-left mb-2 mt-n2"
                >{{ labels.IncorrectEmailAddress }}</span
              >

              <span v-if="!$v.diffAlternateMail.required">{{
                labels.RequiredField
              }}</span>
              <span
                v-if="
                  !$v.diffAlternateMail.email && $v.diffAlternateMail.required
                "
                >{{ labels.EmailInvalidFormat }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="vgs-error-messages" v-html="get_error_messages"></div>
      <div class="row justify-content-space-between">
        <div class="col-md-7 col-sm-6">
          <p>
            <a href="#" class="underline-link" @click="fm_reset()">{{
              labels.UndoChanges
            }}</a>
            <br />
            <a href="#" @click="fm_delete()" class="underline-link">{{
              labels.DeleteUser
            }}</a>
          </p>
        </div>
        <div class="col-md-5 col-sm-6 button-align">
          <b-button
            :aria-label="labels.Save"
            type="submit"
            variant="primary"
            @click="fm_submit"
            :disabled="submitDisabled"
            class="mt-2 save-button rounded vgs-form-button"
            >{{ labels.Save }}</b-button
          >
        </div>
      </div>
    </b-form>
    <div v-if="!isShow" class="successful-register">
      <div class="vgs-error-messages" v-html="get_error_messages"></div>
    </div>

    <b-modal
      ref="delete-user-info-modal"
      id="modal"
      modal-class="delete-user-info-modal"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div class="header-container">
          <!-- Emulate built-in modal header close button action -->
          <button class="custom-close" @click="close()">&times;</button>
          <p class="modal-title">{{ labels.DeleteUser }}</p>
          <p class="my-2">
            <!-- Adjust margin-top value -->
            {{ labels.DeleteUserInfoText }}
          </p>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import PasswordComplexity from "@/components/PasswordComplexity.vue";
import IconSet from "@/structure/IconSet.vue";
import { vcm } from "@/mixins/vcm";
import { mapActions } from "vuex";
import { required, email, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [vcm],
  components: {
    PasswordComplexity,
    IconSet,
  },
  name: "UserDataForm",
  data() {
    return {
      mailShow:
        localStorage.getItem("getalternate") ||
        !localStorage.getItem("alternate"),
      registerKeySet: this.$store.state.setting.RegisterKeySet.RegisterKey,
      registerTypeName:
        this.$store.state.setting.RegisterKeySet.RegisterKeyTypeName,
      diffAlternateMail: localStorage.getItem("alternate") || "",
      differentEmailError: false,
      EmailAlreadyExistsError: false,
      isSuccess: false,
      isShow: true,
      labels: {
        Municipality: this.get_scope_translation("Municipality", "label"),
        Salutation: this.get_scope_translation("Salutation", "label"),
        FirstName: this.get_scope_translation("FirstName", "label"),
        Name: this.get_scope_translation("Name", "label"),
        LastName: this.get_scope_translation("LastName", "label"),
        AcademicTitle: this.get_scope_translation("AcademicTitle", "label"),
        PostNominalTitles: this.get_scope_translation(
          "PostNominalTitles",
          "label"
        ),
        Street: this.get_scope_translation("Street", "label"),
        StreetNumber: this.get_scope_translation("StreetNumber", "label"),
        ApartmentNumber: this.get_scope_translation("ApartmentNumber", "label"),
        PostalCode: this.get_scope_translation("PostalCode", "label"),
        City: this.get_scope_translation("City", "label"),
        DateOfBirth: this.get_scope_translation("DateOfBirth", "label"),
        EMail: this.get_scope_translation("EMail", "label"),
        Password: this.get_scope_translation("Password", "label"),
        PasswordRepeated: this.get_scope_translation(
          "PasswordRepeated",
          "label"
        ),
        SignUp: this.get_scope_translation("SignUp", "label"),
        RequiredField: this.get_scope_translation("RequiredField", "label"),
        PasswordComplexityInfo: this.get_scope_translation(
          "PasswordComplexityInfo",
          "label"
        ),
        AdditionalInfo: this.get_scope_translation("AdditionalInfo", "label"),
        Staircase: this.get_scope_translation("Staircase", "label"),
        CommunityDropdownRequired: this.get_scope_translation(
          "CommunityDropdownRequired",
          "label"
        ),
        FirstnameRequired: this.get_scope_translation(
          "FirstnameRequired",
          "label"
        ),
        SurnameRequired: this.get_scope_translation("SurnameRequired", "label"),
        DateofBirthRequired: this.get_scope_translation(
          "DateofBirthRequired",
          "label"
        ),
        UserDataFormBirthDateNotValid: this.get_scope_translation(
          "UserDataFormBirthDateNotValid",
          "label"
        ),
        EmailRequired: this.get_scope_translation("EmailRequired", "label"),
        EmailInvalidFormat: this.get_scope_translation(
          "EmailInvalidFormat",
          "label"
        ),
        PasswordRequired: this.get_scope_translation(
          "PasswordRequired",
          "label"
        ),
        PasswordNotStrong: this.get_scope_translation(
          "PasswordNotStrong",
          "label"
        ),
        ConfirmPasswordRequired: this.get_scope_translation(
          "ConfirmPasswordRequired",
          "label"
        ),
        ConfirmPasswordNotMatched: this.get_scope_translation(
          "ConfirmPasswordNotMatched",
          "label"
        ),
        EmailInfoText: this.get_scope_translation("EmailInfoText", "label"),
        GetAlternateEmail: this.get_scope_translation(
          "GetAlternateEmail",
          "label"
        ),
        UndoChanges: this.get_scope_translation("UndoChanges", "label"),
        DeleteUser: this.get_scope_translation("DeleteUser", "label"),
        AlternateEmail: this.get_scope_translation("AlternateEmail", "label"),
        Save: this.get_scope_translation("Save", "label"),
        IncorrectEmailAddress: this.get_scope_translation(
          "IncorrectEmailAddress",

          "label"
        ),
        DeliveryData: this.get_scope_translation("DeliveryData", "label"),
        EmailAlreadyExists: this.get_scope_translation(
          "EmailAlreadyExists",
          "label"
        ),
        AlternateEmailAlreadyExists: this.get_scope_translation(
          "AlternateEmailAlreadyExists",
          "label"
        ),
        DeleteUserInfoText: this.get_scope_translation(
          "DeleteUserInfoText",
          "label"
        ),
      },
      person: {},
      messageSetting: {},
      userAddress: {},
      PersonalPhone: null,
      submitted: false,
      phoneNumberUUID: null,
      dateofbirth: null,
      password: "",
      passwordconfirmation: "",
      submitDisabled: false,
    };
  },
  computed: {
    setPerson() {
      return { ...this.$store.state.setting.person };
    },
    MainBankAccount() {
      return { ...this.$store.state.setting.MainBankAccount };
    },
    Address() {
      return { ...this.$store.state.setting.Address };
    },
    UserInformation() {
      return { ...this.$store.state.setting.UserInformation };
    },
    CountryList() {
      return this.$store.getters["setting/getCountryList"];
    },
    MessageSetting() {
      return { ...this.$store.state.setting.MessageSetting };
    },
    DateOfBirth() {
      return this.get_formatted_date(
        this.$store.state.setting.person.DateOfBirth,
        "DD.MM.YYYY"
      );
    },
    sourceSelectionOptions() {
      return this.$store.state.initialize.sourceSelectionOptions;
    },
    shouldHidePassword() {
      return this.$store.state.setting.NotFullyRegisteredUser;
    },
    shouldDisableNames() {
      const registerKeySet =
        this.$store.state.setting.RegisterKeySet.RegisterKey;
      const registerTypeName =
        this.$store.state.setting.RegisterKeySet.RegisterKeyTypeName;
      const stateName = this.person.StateName;

      const validStateNames = [
        "User",
        "UserRegistration",
        "Created",
        "DataIncomplete",
        "DataComplete",
        "Unidentified",
        "ManualAssignment",
      ];

      const firstConditionSatisfied =
        !(validStateNames.includes(stateName)) &&
        registerKeySet === "" &&
        registerTypeName === "";
      const secondConditionSatisfied =
        registerKeySet !== "" && registerTypeName !== "";

      return firstConditionSatisfied || secondConditionSatisfied;
    },
    UserSignIn() {
      return { ...this.$store.state.setting.UserSignIn };
    },
  },
  validations: {
    userAddress: {
      Email: { required, email },
    },
    person: {
      FirstName: {
        required: function () {
          return this.registerTypeName === "BPK-SA" ||
            this.person.TypeName === "NaturalPerson"
            ? required(this.person.FirstName)
            : true;
        },
      },
      LastName: {
        required: function () {
          return this.registerTypeName === "BPK-SA" ||
            this.person.TypeName === "NaturalPerson"
            ? required(this.person.LastName)
            : true;
        },
      },
      Name: {
        required: function () {
          return this.registerTypeName === "UR" ||
            this.person.TypeName === "LegalPerson"
            ? required(this.person.Name)
            : true;
        },
      },
      SourceUUID: { required },
    },
    dateofbirth: {
      isDate(dateofbirth) {
        const regex =
          /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
        return regex.test(dateofbirth) || dateofbirth.length == 0;
      },
    },
    password: {
      required: function () {
        return this.shouldHidePassword ? required(this.password) : true;
      },
    },
    passwordconfirmation: {
      required: function () {
        return this.shouldHidePassword
          ? required(this.passwordconfirmation)
          : true;
      },
      sameAsPassword: sameAs("password"),
    },
    diffAlternateMail: {
      required: function (value) {
        if (this.mailShow && !value) {
          return false;
        }
        return true;
      },
      email,
      emailCheck: function (value) {
        if (!value) {
          return true;
        } else if (
          value.toLowerCase() === this.userAddress.Email.toLowerCase()
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  mounted() {
    const FiledLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "UserDataForm"
    );
    Object.assign(this.labels, FiledLabels);
    this.diffAlternateMail =
      localStorage.getItem("alternate") || this.MessageSetting.AlternateEMail;

    this.mailShow = localStorage.getItem("getalternate") === "true";
  },
  watch: {
    mailShow: function (newVal) {
      if (!newVal) {
        this.diffAlternateMail = null; // Clear the differentAlternate field when mailShow is false
      }
    },
    Address: function () {
      this.dateofbirth = this.get_formatted_date(
        this.$store.state.setting.person.DateOfBirth,
        "DD.MM.YYYY"
      );
      this.messageSetting = JSON.parse(
        JSON.stringify(this.$store.state.setting.MessageSetting)
      );
      this.person = JSON.parse(
        JSON.stringify(this.$store.state.setting.person)
      );
      this.userAddress = JSON.parse(
        JSON.stringify(this.$store.state.setting.Address)
      );
      // let personEmail = this.$store.state.setting.UserInformation.filter(
      //   (obj) => obj.TypeName === "PersonalEMail"
      // );
      this.PersonalPhoneArr = this.$store.state.setting.UserInformation.filter(
        (obj) => obj.TypeName === "PersonalPhone"
      );
      this.mainBankAccount = JSON.parse(
        JSON.stringify(this.$store.state.setting.MainBankAccount)
      );
      this.userAddress.Email = this.$store.state.setting.UserSignIn.Email;
      this.PersonalPhone =
        this.PersonalPhoneArr[0] && this.PersonalPhoneArr[0].Value;
      this.phoneNumberUUID = !this.PersonalPhoneArr.length
        ? this.person.Uuid
        : this.PersonalPhoneArr[0].UUID;
    },
  },
  methods: {
    ...mapActions({
      getUser: "setting/getUserAction",
      deleteUserNotification: "setting/getDeleteUserNotification",
    }),
    onBlur(selectElement) {
      let element = this.$refs[selectElement];
      let inputElement = this.$refs[selectElement + "_form-control"];
      if (selectElement === "dateofbirth") {
        this.$v.dateofbirth.$touch();
      }
      if (selectElement === "email") {
        this.$v.userAddress.Email.$touch();
      }
      if (selectElement === "password") {
        this.$v.password.$touch();
      }
      if (selectElement === "passwordconfirmation") {
        this.$v.passwordconfirmation.$touch();
      }
      if (element.classList.contains("onfocus") && !inputElement.value) {
        element.classList.remove("onfocus");
      }
    },
    onFocus(selectElement) {
      let element = this.$refs[selectElement];
      if (!element.classList.contains("onfocus")) {
        element.className += " onfocus";
      }
    },
    checkLength($event) {
      if (
        this.userAddress.PostalCode.length > 3 &&
        $event.keyCode !== 8 &&
        $event.keyCode !== 9 &&
        $event.keyCode !== 69
      )
        $event.preventDefault();
    },
    fm_reset() {
      this.messageSetting = JSON.parse(
        JSON.stringify(this.$store.state.setting.MessageSetting)
      );
      this.diffAlternateMail =
        localStorage.getItem("alternate") || this.messageSetting.AlternateEMail;

      this.mailShow =
        localStorage.getItem("getalternate") === "true" ||
        this.messageSetting.ActiveAlternateEmail;
      this.diffAlternateMail = this.messageSetting.AlternateEMail;
      this.dateofbirth = this.get_formatted_date(
        this.$store.state.setting.person.DateOfBirth,
        "DD.MM.YYYY"
      );
      this.person = JSON.parse(
        JSON.stringify(this.$store.state.setting.person)
      );
      this.userAddress = JSON.parse(
        JSON.stringify(this.$store.state.setting.Address)
      );
      // let personEmail = this.$store.state.setting.UserInformation.filter(
      //   (obj) => obj.TypeName === "PersonalEMail"
      // );
      this.PersonalPhoneArr = this.$store.state.setting.UserInformation.filter(
        (obj) => obj.TypeName === "PersonalPhone"
      );
      this.mainBankAccount = JSON.parse(
        JSON.stringify(this.$store.state.setting.MainBankAccount)
      );
      this.userAddress.Email = this.$store.state.setting.UserSignIn.Email;
      this.PersonalPhone =
        this.PersonalPhoneArr[0] && this.PersonalPhoneArr[0].Value;
      this.phoneNumberUUID = !this.PersonalPhoneArr.length
        ? this.person.Uuid
        : this.PersonalPhoneArr[0].UUID;
    },
    fm_delete() {  
       this.deleteUserNotification().then((result) => {
        if(result.responseCode === '200' || result.responseMessage === 'Ok'){
          this.$refs["delete-user-info-modal"].show();
       }
       })
       },
    fm_submit(evt) {
      evt.preventDefault();
      this.submitted = true;
      var fieldsValidated = true;
      this.submitDisabled = true;
      this.$v.$touch();

      if (
        this.$v.person.$invalid ||
        this.$v.userAddress.$invalid ||
        this.$v.password.$invalid ||
        (this.shouldHidePassword? (this.password_complexity_level < 4) :false) ||
        this.$v.dateofbirth.$invalid ||
        this.$v.passwordconfirmation.$invalid ||
        this.$v.diffAlternateMail.$invalid
      ) {
        this.submitDisabled = false;
        fieldsValidated = false;
      }
      let data = {
        UserInformation: [
          {
            UUID: this.phoneNumberUUID,
            TypeName: "PersonalPhone",
            Value: this.PersonalPhone,
          },
        ],
        MainBankAccount: {
          UUID: this.MainBankAccount.UUID,
          TypeName: this.MainBankAccount.TypeName,
          Owner: this.mainBankAccount.Owner,
          BankName: this.MainBankAccount.BankName,
          BankCode: this.MainBankAccount.BankCode,
          IBAN: this.mainBankAccount.IBAN,
          BIC: this.MainBankAccount.BIC,
        },

        MainAddress: {
          TypeName:"Delivery",
          Street: this.userAddress.Street ? this.userAddress.Street : "",
          StreetNumber: this.userAddress.StreetNumber
            ? this.userAddress.StreetNumber
            : "",
          Staircase: this.userAddress.Staircase
            ? this.userAddress.Staircase
            : "",
          ApartmentNumber: this.userAddress.ApartmentNumber
            ? this.userAddress.ApartmentNumber
            : "",
          PostalCode: this.userAddress.PostalCode
            ? this.userAddress.PostalCode
            : "",
          City: this.userAddress.City ? this.userAddress.City : "",
          Country: this.userAddress.Country
            ? parseInt(this.userAddress.Country)
            : 1,
          Phone: this.userAddress.Phone,
        },
        MessageSetting: {
          GetMessages: this.messageSetting.GetMessages,
          GetNotification: this.messageSetting.GetNotification,
          AlternateEMail: this.diffAlternateMail,
          ActiveAlternateEmail: this.mailShow,
        },
        DateOfBirth: this.dateofbirth,
        Name: this.person.Name,
        FirstName: this.person.FirstName,
        LastName: this.person.LastName,
        Middlename: "",
        Salutation:  this.person.Salutation ? parseInt(this.person.Salutation) : 0,
        SourceUUID: this.person.SourceUUID ? this.person.SourceUUID : "00000000-0000-0000-0000-000000000000",
        AcademicTitles: this.person.AcademicTitles,
        PostNominalTitles: this.person.PostNominalTitles,
        UserSignIn: {
          EMail: this.userAddress.Email,
          Password: this.password,
          StateName: this.$store.state.setting.NotFullyRegisteredUser
            ? "Created"
            : this.UserSignIn.StateName,
          TypeName: this.$store.state.setting.NotFullyRegisteredUser
            ? "User"
            : this.UserSignIn.TypeName,
        },
      };
      if (fieldsValidated) {
        var myDateOfBirth = "";
        if (this.dateofbirth.trim()) {
          var dateString = this.dateofbirth;
          var parts = dateString.split(".");
          var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
          myDateOfBirth = mydate.toDateString();
        }
        data.DateOfBirth = myDateOfBirth;
        this.$root.data_access.setIdentifier("user.setting.updated");
        this.$root.data_access.setReceiver("component", this);
        this.$root.data_access.addPathParameter(
          "user-uuid",
          this.$root.get_user_info("Uuid", this.person.Uuid)
        );
        this.$root.data_access.setRequestBody(data);
        this.$root.data_access.call("user_settings");
      }
    },
    receive(dataObject) {
      if (dataObject) {
        switch (dataObject.identifier) {
          case "user.setting.updated":
            if (
              dataObject.data.responseCode === "200" &&
              dataObject.data.responseMessage === "Ok"
            ) {
              this.$root.add_alert(
                "Erfolg",
                this.labels.SuccessUpdateProfile,
                "check2-circle"
              );
              this.differentEmailError = false;
              this.EmailAlreadyExistsError = false;
              this.submitDisabled = false;
              localStorage.setItem("getalternate", this.mailShow);
              localStorage.setItem("alternate", this.diffAlternateMail);
              this.getUser();
            } else if (dataObject.data.responseCode === "409") {
              this.EmailAlreadyExistsError = true;
              this.submitDisabled = false;
            } else if (dataObject.data.responseCode === "406") {
              this.differentEmailError = true;
              this.submitDisabled = false;
            }
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-user-info-modal {
  display: flex !important;
  justify-content: center !important;
  // align-items: center !important;
  height: 100vh;
  margin-top: 10%;
}
.error-new-class{
  width: 100%; 
  text-align: left
}
.save-button {
  height: 45px;
  width: 171px;
}
.button-align{
  text-align: right;
}
.input-height {
  height: 50px;
}
.input-margin {
  margin-bottom: 2rem;
  height: 50px;
}
.modal-body {
  padding: 0;
  position: relative;
  flex: 1 1 auto; /* Set padding to 0 to remove the default 1rem padding */
}
.header-container {
  text-align: left;
  padding-left: 7px;
  padding-right: 7px;
}
.modal-title {
  margin-top: 10px; /* Reset top margin */
  margin-bottom: 0.25rem; /* Adjust margin-bottom value */
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold; /* Add font weight if desired */
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  outline: 0;
}
@media (min-width: 600px) {
  .modal-content {
    margin-left: 29px;
  }
}
.vcs-title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0 0 0.25rem 0.75rem;
  margin-bottom: 1.75rem;
  border-bottom: 1px solid #ffffff;
}
.custom-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: transparent;
  color: black;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;
  outline: none;
}
.custom-control-label {
  padding-bottom: 14px !important;
}
.leftspace {
  margin-left: 29px;
}
.custom-control {
  z-index: 0;
}
@media (max-width: 1077px) and (min-width: 992px) {
  .button-align{
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .custom-control-label {
    font-size: 14px;
  }
}
.Alt-input {
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .save-button {
    height: auto;
    width: inherit;
}
}
@media only screen and (max-width: 991px) {
  .Alt-input {
    width: 100%;
  }
}
.vcs-required {
  width: 100%;
  text-align: right;
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #121212;
}
.error-class {
  font-size: 17px;
  text-align: left;
  margin-bottom: 13px;
}
.error-class > span {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.form-control {
  height: 50px;
}
.vgs-form-indent form .form-control.is-invalid {
  background-image: none !important;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.dropdown-icon-top {
  top: 33px !important;
}

.form-field__control > .vgs-select-chevron {
  top: 30px;
}
.vgs-select-chevron {
  top: 15px;
}
.position-relative > label {
  display: block;
  position: absolute;
  text-align: left;
  top: 14px;
  left: 14px;
  font-size: 16px;
  transition: all 0.3s;
}

.onfocus > input + label,
.onfocus > select + label {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-webkit-transition: 0.3s all ease-in-out;
  top: -18px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
}

.successful-register .vgs-error-messages {
  margin-top: 1.5rem;
  background-color: #ffdddd !important;
  padding: 30px 20px;
  text-align: center;
  border: 1px solid #f44336;
  margin: 50px auto 50px;
}
.successful-register .vgs-error-messages ul {
  margin-bottom: 0;
}
.successful-register .vgs-error-messages ul li {
  text-align: center;
  margin-bottom: 0;
}
.warning-message-box {
  background-color: #fffce5;
  border: 1px solid #ffde00;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.alert-icon {
  margin-right: 10px;
  margin-bottom: 20px;
  color: #000000f6;
}
@media only screen and (min-width: 280px) {
  .delete-user-info-modal {
    display: flex !important;
    justify-content: center !important;
    height: 100vh;
    margin-top: 16%;
  }
}
.alert-text {
  flex: 1;
}
.underline-link {
  text-decoration: underline;
}
</style>
