<template>
  <Page>
    <h1>{{ labels.Title }}</h1>
    <p v-html="labels.Description"></p>
    <div class="vgs-form-indent">
      <UserDataForm />
    </div>
  </Page>
</template>

<script>
import Page from "@/structure/Page.vue";
import UserDataForm from "@/forms/UserDataForm.vue";
import axios from "axios";
import Vue from 'vue';
export default {
  name: "UserData",
  components: {
    Page,
    UserDataForm,
  },
  data() {
    return {
      labels: {
        Title:" Benutzerdaten andern",
        Description: "Andern Sie ihre Daten und bestatigen Sie im Anschuluss mit Speicherm."

      },
    };
  },
  
  async beforeCreate() {
    try {
     
      const Url = process.env.VUE_APP_BASE_URL + `/v1/pages/userdata`;
     const token = Vue.vclGet('access_token', '');
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
         headers: {
        Authorization: `Bearer ${token}`,
      },
      });
      if (response.data.responseCode === "200" && response.data.response) {
        this.labels.Title = response.data.response.Title;
        this.labels.Description = response.data.response.Description;
      }
    } catch (e) {
      console.log("Error : " + e);
    }
  },
};
</script>

<style scoped>
.vgs-form-indent {
    width: 50%;
    margin-left: 0;
    margin-top: 1.875rem;
}
@media (max-width: 991px){

.vgs-form-indent {
    width: 97%;
    margin-left: auto;
}
}
h1 {
  font-size: 1.875rem;
  line-height: 2.563rem;
  /* color: #primaryColor; */
}
p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000;
}
</style>
